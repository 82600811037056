import React from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

const CustomSwitchStyled = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 4,
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(16px)",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--clr-accent-100)",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 17,
    height: 17,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#979797",
  },
}));

const CustomSwitch = ({ isDarkTheme, handleChangeTheme }) => {
  return (
    <CustomSwitchStyled
      className="toggle-switch"
      onChange={handleChangeTheme}
      checked={isDarkTheme}
    />
  );
};

export default CustomSwitch;
